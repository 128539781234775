import React, {  } from 'react'
import TargetDiscovery from "src/assembled/Technology/TargetDiscovery"
import { Footer } from "src/components"
import { SEO } from "src/components/utils"


export default function Technology({ location }) {

    // seo
    const seo = <SEO
    title="Technology"
    description="인공지능을 기반으로한 디어젠의 Target-Discovery 기술을 소개합니다."
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <TargetDiscovery location={location} />
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}
